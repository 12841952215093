
  import ScreenSize from '@/mixins/ScreenSize';
  import Carousel from '@/mixins/Carousel';

  export default {
    filters: {
      truncate(value) {
        if (!value) return '';
        if (value.length <= 13) return value;

        return value.substring(0, 13) + '...';
      },
    },
    mixins: [ScreenSize, Carousel],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        slides: [],
        numberOfElements: 'seven_7',
        sliderElements: this.section.buttons,
      };
    },
    computed: {
      numberOfColumns() {
        const numberOfElements = this.getNumberOfElementsInSlide(
          this.numberOfElements
        );
        let colNumber = 0;
        if (
          (numberOfElements === '2' && this.isDesktopOrTablet) ||
          this.$vuetify.breakpoint.width <= 610
        ) {
          colNumber = 6;
        } else if (numberOfElements === '4' && this.isDesktopOrTablet) {
          colNumber = 3;
        } else if (
          (numberOfElements === '3' && this.isDesktopOrTablet) ||
          this.isMobile
        ) {
          colNumber = 4;
        } else if (numberOfElements === '6' && this.isDesktopOrTablet) {
          colNumber = 2;
        }
        return colNumber;
      },
      elementNumber() {
        const elementsPerSlide = this.getNumberOfElementsInSlide(
          this.numberOfElements
        );
        if (this.$vuetify.breakpoint.width <= 610) {
          return 2;
        } else if (
          this.$vuetify.breakpoint.width > 610 &&
          this.$vuetify.breakpoint.width <= 960
        ) {
          return 3;
        } else if (
          this.$vuetify.breakpoint.width > 960 &&
          this.$vuetify.breakpoint.width <= 980
        ) {
          return 5;
        } else if (
          this.$vuetify.breakpoint.width > 980 &&
          this.$vuetify.breakpoint.width <= 1170
        ) {
          return 5;
        } else {
          return elementsPerSlide;
        }
      },
    },
    watch: {
      '$vuetify.breakpoint.width'() {
        this.setSlides();
      },
    },
    mounted() {
      this.setSlides();
    },
    methods: {
      getNumberOfElementsInSlide() {
        const number = this.numberOfElements.split('_');
        if (number.length > 1) {
          return number[1];
        }
      },
    },
  };
